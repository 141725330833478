import React from 'react'
import { FiExternalLink } from 'react-icons/fi'

function Inscriptions() {
    return (
        <div>
            <div className="m-8 p-4 bg-emerald-300 border-black border rounded shadow-lg">
                <p className="text-2xl font-bold p-5">Inscription details </p>
                <a
                    href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRGyyxpkU9cKRpdnqupedXvQ9jeF7r5mpdr7bwSajIu7YdTM0yzQehDbHFid-JtdV_cWlniI1OlhaHD/pubhtml?gid=0&single=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-2xl font-bold p-5 flex items-center"
                >
                    <span>Link to Google Sheets</span>
                    <FiExternalLink className="ml-2" size={24} />
                </a>
            </div>
        </div>
    )
}

export default Inscriptions
