import React from 'react'
import { useState, useEffect } from 'react'
import alienBody from './contents/alienbody.png'
import goldBody from './contents/goldbody.png'
import greenBody from './contents/greenbody.png'
import magmaBody from './contents/magmabody.png'
import orangeBody from './contents/orangebody.png'
import pinkBody from './contents/pinkbody.png'
import redBody from './contents/redbody.png'
import rustBody from './contents/rustbody.png'
import stoneBody from './contents/stonebody.png'
import black from './contents/backgrounds/Black.png'
import darkBlue from './contents/backgrounds/DarkBlue.png'
import darkGreen from './contents/backgrounds/DarkGreen.png'
import grey from './contents/backgrounds/Grey.png'
import orange from './contents/backgrounds/Orange.png'
import pink from './contents/backgrounds/Pink.png'
import red from './contents/backgrounds/Red.png'
import yellow from './contents/backgrounds/Yellow.png'
import metamaskLogo from './contents/metamask-logo.png'
import { ethers, utils } from 'ethers'
import { whitelist } from './whitelist'
import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'
import { Buffer } from 'buffer'

Buffer.from('anything', 'base64')
window.Buffer = window.Buffer || require('buffer').Buffer

const contractAddress = '0x2Ac0fBeF1431FdAe74a9E5614f738798Da6f7B36'
const contractAbi = require('./dapp/abi.json')
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner()
const contract = new ethers.Contract(contractAddress, contractAbi, signer)

// Add conditionals to the sale enabled status

function MintView() {
    const [walletConnected, setWalletConnected] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [amount, setAmount] = useState(0)
    const [paused, setPaused] = useState(true)
    const [whitelistMintEnabled, setWhitelistMintEnabled] = useState(false)
    const [mintedCount, setMintedCount] = useState(0)

    useEffect(() => {
        async function fetchData() {
            const isPaused = await contract.paused()
            const isWhitelistMintEnabled = await contract.whitelistMintEnabled()
            const totalSupply = await contract.totalSupply()
            setMintedCount(totalSupply.toNumber())
            setPaused(isPaused)
            setWhitelistMintEnabled(isWhitelistMintEnabled)
        }
        fetchData()
    }, [contract])

    console.log('paused', paused)
    console.log('whitelistMintEnabled', whitelistMintEnabled)

    if (!walletConnected && typeof window.ethereum !== 'undefined') {
        // Check if the user is already connected to a wallet
        window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
            if (accounts.length > 0) {
                setAccounts(accounts)
                setWalletConnected(true)
            }
        })
    }

    const connectWallet = () => {
        if (typeof window.ethereum !== 'undefined') {
            window.ethereum
                .request({ method: 'eth_requestAccounts' })
                .then((accounts) => {
                    setAccounts(accounts)
                    setWalletConnected(true)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const handleIncrement = () => {
        if (amount < 3) {
            setAmount(amount + 1)
        }
    }

    const handleDecrement = () => {
        if (amount > 0) {
            setAmount(amount - 1)
        }
    }

    // Public Mint Function
    const handleMint = async () => {
        try {
            const value = utils.parseEther((0.0101 * amount).toString())
            const tx = await contract.mint(amount, { value })
            console.log(tx)
            console.log(value)
        } catch (error) {
            console.log(error)
        }
    }

    // Whitelist Mint Function
    const handleWhitelistMint = async () => {
        try {
            const signer = await provider.getSigner()
            const address = await signer.getAddress()
            console.log('Signer address is', address)

            if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
                throw new Error('Invalid signer address')
            }

            const whitelistedUsers = whitelist
            const leafNodes = whitelistedUsers.map((user) => {
                return keccak256(user) // keccak256 is a function that hashes the user address
            })

            const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })
            const rootHash = merkleTree.getRoot().toString('hex')
            console.log('THE ROOT HASH IS', '0x' + rootHash)

            let value = 0
            if (amount === 2) {
                value = 0.0101
            } else if (amount === 3) {
                value = 0.0202
            }
            const transaction = await contract.whitelistMint(amount, merkleTree.getHexProof(keccak256(address)), {
                value: utils.parseEther(value.toString()),
            })
            console.log(transaction)
        } catch (error) {
            console.log(error)
        }
    }

    // Whitelist & Public Mint Function
    const handleWhitelistAndPublicMint = async () => {
        const signer = await provider.getSigner()
        const address = await signer.getAddress()
        try {
            // Check if the user is whitelisted
            const isWhitelisted = whitelist.includes(address)
            console.log('Is whitelisted?', isWhitelisted)

            if (isWhitelisted) {
                await handleWhitelistMint()
            } else {
                await handleMint()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {/* Minting options */}
            <div>
                <div className="items-center justify-center flex flex-col p-5">
                    {/* Rest of the minting view goes here */}

                    <div className="grid grid-cols-3 gap-4">
                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={goldBody}
                            style={{
                                backgroundImage: `url(${yellow})`,
                                backgroundSize: 'cover',
                            }}
                        />

                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={magmaBody}
                            style={{
                                backgroundImage: `url(${black})`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={pinkBody}
                            style={{
                                backgroundImage: `url(${pink})`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={alienBody}
                            style={{
                                backgroundImage: `url(${darkBlue})`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={greenBody}
                            style={{
                                backgroundImage: `url(${darkGreen})`,
                                backgroundSize: 'cover',
                            }}
                        />

                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={stoneBody}
                            style={{
                                backgroundImage: `url(${black})`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={redBody}
                            style={{
                                backgroundImage: `url(${red})`,
                                backgroundSize: 'cover',
                            }}
                        />

                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={rustBody}
                            style={{
                                backgroundImage: `url(${grey})`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <img
                            className="rounded w-28 h-28 sm:w-[10.9rem] sm:h-[10.9rem]"
                            src={orangeBody}
                            style={{
                                backgroundImage: `url(${orange})`,
                                backgroundSize: 'cover',
                            }}
                        />
                    </div>
                    <br />

                    {/* Wallet connect button */}
                    <div className="items-center justify-center flex flex-col p-5">
                        <div className="p-2" />

                        <div className="bg-emerald-800 p-5 rounded-lg p-2">
                            {!walletConnected && (
                                <div className="text-center">
                                    <p className="text-sm text-white mt-2 mb-4">
                                        Please connect your wallet in order to mint
                                    </p>
                                    <div className="flex flex-col">
                                        <button
                                            className="bg-emerald-700 hover:bg-emerald-900 text-white font-bold py-2 px-4 rounded mx-auto flex items-center justify-center"
                                            onClick={connectWallet}
                                        >
                                            <img src={metamaskLogo} alt="Metamask Logo" className="w-4 h-4 mr-2" />
                                            Connect Wallet
                                        </button>
                                    </div>
                                </div>
                            )}

                            {walletConnected && (
                                <div>
                                    <label htmlFor="amount" className="text-xl font-bold">
                                        Amount
                                    </label>
                                    {!paused && whitelistMintEnabled && (
                                        <p className="mb-5">
                                            Public Mint & Bitlist Mint active
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600 ml-2">
                                                <span className="absolute inline-flex h-3 w-3 rounded-full bg-green-700 opacity-75 animate-ping"></span>
                                            </span>
                                        </p>
                                    )}

                                    {!paused && !whitelistMintEnabled && (
                                        <p className="mb-5">
                                            Public Mint active
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600 ml-2">
                                                <span className="absolute inline-flex h-3 w-3 rounded-full bg-green-700 opacity-75 animate-ping"></span>
                                            </span>
                                        </p>
                                    )}

                                    {paused && whitelistMintEnabled && (
                                        <p className="mb-5">
                                            Bitlist Mint active
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600 ml-2">
                                                <span className="absolute inline-flex h-3 w-3 rounded-full bg-green-700 opacity-75 animate-ping"></span>
                                            </span>
                                        </p>
                                    )}

                                    {paused && !whitelistMintEnabled && (
                                        <p className="mb-5">
                                            Mint not active
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600 ml-2">
                                                <span className="absolute inline-flex h-3 w-3 rounded-full bg-red-700 opacity-75 animate-ping"></span>
                                            </span>
                                        </p>
                                    )}

                                    <div className="flex justify-center items-center mt-4">
                                        <button
                                            className="bg-emerald-700 hover:bg-emerald-900 text-white font-bold py-2 px-4 rounded"
                                            onClick={handleDecrement}
                                        >
                                            -
                                        </button>
                                        <input
                                            type="number"
                                            id="amount"
                                            name="amount"
                                            className="text-center py-2 px-4"
                                            value={amount}
                                            disabled
                                        />
                                        <button
                                            className="bg-emerald-700 hover:bg-emerald-900 text-white font-bold py-2 px-4 rounded"
                                            onClick={handleIncrement}
                                        >
                                            +
                                        </button>
                                    </div>

                                    {!paused && whitelistMintEnabled && (
                                        <div className="flex justify-center items-center mt-4">
                                            <button
                                                className="bg-emerald-700 hover:bg-emerald-900 text-white font-bold py-2 px-4 rounded"
                                                onClick={handleWhitelistAndPublicMint}
                                            >
                                                Mint
                                            </button>
                                        </div>
                                    )}

                                    {!paused && !whitelistMintEnabled && (
                                        <div className="flex justify-center items-center mt-4">
                                            <button
                                                className="bg-emerald-700 hover:bg-emerald-900 text-white font-bold py-2 px-4 rounded"
                                                onClick={handleMint}
                                            >
                                                Public Mint
                                            </button>
                                        </div>
                                    )}

                                    {paused && whitelistMintEnabled && (
                                        <div className="flex justify-center items-center mt-4">
                                            <button
                                                className="bg-emerald-700 hover:bg-emerald-900 text-white font-bold py-2 px-4 rounded"
                                                onClick={handleWhitelistMint}
                                            >
                                                Bitlist Mint
                                            </button>
                                        </div>
                                    )}

                                    {paused && !whitelistMintEnabled && (
                                        <div className="text-red-500 text-center mt-2">
                                            You can't mint when mint has not started
                                        </div>
                                    )}

                                    <div className="border-b-2 border-emerald-700">
                                        <div className="p-5">
                                            <p>Price: 0</p>

                                            <p>Minted: {mintedCount}/2222</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MintView
