import React from 'react'

function MintDetails() {
    return (
        <div>
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold tracking-wide m-4">Mint Details</h2>
                <div className="flex justify-center items-center mb-4">
                    <div className="w-12 h-1 bg-white rounded-full animate-pulse mr-4"></div>
                    <p className="text-lg font-medium">Max Supply 2222</p>
                    <p className="text-lg font-medium"></p>
                </div>

                <p>Public starts 15 minutes after Bitlist (if any supply left)</p>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2">
                    <div className="m-8 p-4 bg-emerald-500 border-black border rounded shadow-lg">
                        <h2 className="text-2xl font-bold mb-5">Bitlist</h2>
                        <p className="text-lg font-medium">
                            Users can claim up to 3 NFTs in one transaction, with one NFT being free and the rest priced
                            at 0.0101. You can only mint once, so choose the amount you want carefully
                        </p>
                    </div>
                </div>
                <div className="w-full md:w-1/2">
                    <div className="m-8 p-4 bg-emerald-500 border-black border rounded shadow-lg">
                        <h2 className="text-2xl font-bold mb-5">Public</h2>
                        <p className="text-lg font-medium">Amount: 3 per wallet</p>
                        <p className="text-lg font-medium">Price: 0.0101 ETH</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MintDetails
