import React, { useState } from 'react'
import { whitelist } from './whitelist'

function WalletChecker() {
    const [walletAddress, setWalletAddress] = useState('')
    const [resultMessage, setResultMessage] = useState('')
    const [resultBoxClass, setResultBoxClass] = useState('')

    const handleInputChange = (event) => {
        setWalletAddress(event.target.value)
    }

    const handleButtonClick = () => {
        if (!walletAddress) {
            setResultMessage('Please enter a wallet address')
            setResultBoxClass('text-yellow-600')
            return
        }

        const matchingElement = whitelist.find((element) => element.toLowerCase() === walletAddress.toLowerCase())
        const isWhitelisted = !!matchingElement

        // Set the result message and box class
        setResultMessage(isWhitelisted ? 'Wallet is Bitlisted' : 'Wallet is not Bitlisted')
        setResultBoxClass(isWhitelisted ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')
    }

    return (
        <div className="flex flex-col items-center space-y-4 m-5">
            <h2 className="text-2xl font-bold">Bitlist Checker</h2>
            <div className="flex flex-col space-y-2">
                <label htmlFor="wallet-address" className="font-bold">
                    Wallet address
                </label>
                <input
                    id="wallet-address"
                    type="text"
                    placeholder="Enter wallet address"
                    value={walletAddress}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded-lg text-black"
                />
            </div>
            <button onClick={handleButtonClick} className="bg-emerald-500 text-white px-4 py-2 rounded-lg">
                Check Wallet
            </button>
            <div className={`text-center font-bold rounded-lg p-2 mt-2 ${resultBoxClass}`}>{resultMessage}</div>
        </div>
    )
}

export default WalletChecker
