import './App.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Inscriptions from './Inscriptions'
import MintDetails from './MintDetails'
import MintView from './MintView'
import WalletChecker from './WalletChecker'

function App() {
    return (
        <Router>
            <div className="min-h-screen bg-cover bg-center blur-glow-bg ">
                <div className="flex flex-col items-center justify-center">
                    <div className="max-w-screen-lg m-4 sm:my-10">
                        <div className="max-w-2xl w-full mx-auto border-2 border-black rounded-lg text-center text-white bg-emerald-600 bg-opacity-90 p-4 sm:p-6 lg:p-8">
                            <header className="py-3">
                                <div className="m-5">
                                    <h1 className="text-3xl font-bold">BitsNFT</h1>
                                    <br />
                                    <p className="text-[15.3px] mb-4">
                                        2222 Bits born on Ethereum with a purpose of getting eternal life in Bitcoin
                                    </p>
                                </div>
                                <nav className="">
                                    <ul className="flex flex-col sm:flex-row justify-center">
                                        <li className="mx-2 mb-2 sm:mb-0">
                                            <Link to="/">
                                                <button className="bg-emerald-700 text-white font-bold py-2 px-4 rounded focus:bg-emerald-900 focus:outline-none">
                                                    Mint Page
                                                </button>
                                            </Link>
                                        </li>
                                        <li className="mx-2 mb-2 sm:mb-0">
                                            <Link to="/mintdetails">
                                                <button className="bg-emerald-700 text-white font-bold py-2 px-4 rounded focus:bg-emerald-900 focus:outline-none">
                                                    Mint Details
                                                </button>
                                            </Link>
                                        </li>
                                        <li className="mx-2 mb-2 sm:mb-0">
                                            <Link to="/inscriptions">
                                                <button className="bg-emerald-700 text-white font-bold py-2 px-4 rounded focus:bg-emerald-900 focus:outline-none">
                                                    Inscriptions
                                                </button>
                                            </Link>
                                        </li>
                                        <li className="mx-2 mb-2 sm:mb-0">
                                            <Link to="/wallet-checker">
                                                <button className="bg-emerald-700 text-white font-bold py-2 px-4 rounded focus:bg-emerald-900 focus:outline-none">
                                                    Wallet Checker
                                                </button>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </header>

                            <main>
                                <Routes>
                                    <Route path="/mintdetails" element={<MintDetails />} />
                                    <Route path="/inscriptions" element={<Inscriptions />} />
                                    <Route path="/wallet-checker" element={<WalletChecker />} />
                                    <Route path="/" element={<MintView />} />
                                </Routes>
                            </main>

                            <footer className="bg-emerald-700 rounded-lg p-8 ">
                                <ul className="flex flex-row items-center justify-center">
                                    <li className="mx-2">
                                        <a href="https://twitter.com/genesisbits">
                                            <img
                                                className="filter invert w-8 h-8"
                                                src="https://img.icons8.com/ios/50/000000/twitter.png"
                                                alt="Twitter"
                                            />
                                        </a>
                                    </li>
                                    <li className="mx-2">
                                        <a href="https://etherscan.io/address/0x2ac0fbef1431fdae74a9e5614f738798da6f7b36">
                                            <img
                                                className="filter invert w-8 h-8"
                                                src="https://img.icons8.com/ios/50/000000/ethereum.png"
                                                alt="Ethereum"
                                            />
                                        </a>
                                    </li>
                                    <li className="mx-2">
                                        <a href="https://opensea.io/collection/bitsnftofficial">
                                            <img
                                                className="filter invert w-8 h-8"
                                                src="https://img.icons8.com/ios/50/000000/opensea.png"
                                                alt="OpenSea"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    )
}

export default App
